import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);

Vue.config.productionTip = false
// 自定义组件——页头
import pageName from "@/components/pageName";
Vue.component("envir-page-name", pageName);

Vue.prototype.Global = {
  getDateNum(theDate){
    const year = theDate.getFullYear();
    const month = theDate.getMonth() + 1 < 10? "0" + (theDate.getMonth() + 1): theDate.getMonth() + 1;
    const date = theDate.getDate() < 10? "0" + theDate.getDate(): theDate.getDate();
    const hh = theDate.getHours() < 10? "0" + theDate.getHours(): theDate.getHours();
    const mm = theDate.getMinutes() < 10? "0" + theDate.getMinutes(): theDate.getMinutes();
    const ss = theDate.getSeconds() < 10? "0" + theDate.getSeconds(): theDate.getSeconds();
    return year + "/" + month + "/" + date + " " + hh  + ":" + mm + ":" + ss;
  },
  pickerOptions: {
    shortcuts: [{
      text: '最近一天',
      onClick(picker) {
        const end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 86400000);
        const start = new Date(new Date(new Date().toLocaleDateString()).getTime() + 86400000);
        start.setTime(start.getTime() - 3600 * 1000 * 24);
        picker.$emit('pick', [start, end]);
      }
    },{
      text: '最近一周',
      onClick(picker) {
        const end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 86400000);
        const start = new Date(new Date(new Date().toLocaleDateString()).getTime() + 86400000);
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近一个月',
      onClick(picker) {
        const end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 86400000);
        const start = new Date(new Date(new Date().toLocaleDateString()).getTime() + 86400000);
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近三个月',
      onClick(picker) {
        const end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 86400000);
        const start = new Date(new Date(new Date().toLocaleDateString()).getTime() + 86400000);
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    }]
  }
}

new Vue({
  render: h => h(App),
  router,
	store,
}).$mount('#app')
