import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const home = r => require.ensure([], () => r(require('@/pages/home')), 'home');
const login = r => require.ensure([], () => r(require('@/pages/login/Login')), 'login');
const mainpage = r => require.ensure([], () => r(require('@/components/mainPage')), 'mainpage');

const match = r => require.ensure([], () => r(require('@/pages/main/match')), 'match');
const game = r => require.ensure([], () => r(require('@/pages/main/game')), 'game');
const order = r => require.ensure([], () => r(require('@/pages/main/order')), 'order');
const user = r => require.ensure([], () => r(require('@/pages/main/user')), 'user');
const picture = r => require.ensure([], () => r(require('@/pages/main/picture')), 'picture');
const rank = r => require.ensure([], () => r(require('@/pages/main/rank')), 'rank');

const other = r => require.ensure([], () => r(require('@/pages/main/other')), 'other');

const routes = [
	{
		path: '/',
		component: login
	},
	{
		path: '/login',
		component: login
	},
	{
		path: '/mainpage',
		component: mainpage,
		name: '',
		children: [{
			path: '',
			component: home,
			meta: [],
		},{
			path: '/home',
			component: home,
			meta: [],
		},{
			path: '/match',
			component: match,
			meta: ['房间管理'],
		},{
			path: '/game',
			component: game,
			meta: ['单局管理'],
		},{
			path: '/order',
			component: order,
			meta: ['交易管理'],
		},{
			path: '/user',
			component: user,
			meta: ['用户管理'],
		},{
			path: '/picture',
			component: picture,
			meta: ['图片管理'],
		},{
			path: '/rank',
			component: rank,
			meta: ['排行管理'],
		}
		,{
			path: '/other',
			component: other,
			meta: ['图片上传'],
		}
	]
	},
]

export default new Router({
	routes,
	strict: process.env.NODE_ENV !== 'production',
})
